import { PoNVoid } from '@/types'
import { ReactElement } from 'react'
import { Tabs as BaseTabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cls } from '@/utils'

export interface TabItemProps {
  value: string
  label: string | ReactElement
}

export interface TabsProps {
  className?: string
  itemClassName?: string
  value: string
  items: TabItemProps[]
  onChange: (value: string) => PoNVoid
}

export default function Tabs({ value, items, onChange, className, itemClassName }: TabsProps) {
  return (
    <BaseTabs
      value={value}
      className={cls('rounded-md bg-surface-hover p-0.5 h-10', className)}
      activationMode='manual'
      onValueChange={(v) => onChange?.(v)}
    >
      <TabsList className='w-full rounded-md p-0 h-9 max-h-full'>
        {items.map((item) => (
          <TabsTrigger
            key={item.value}
            value={item.value}
            className={cls(
              'rounded-md active:bg-surface-primary active:text-text-on-color text-body-lg text-icon py-2 px-5 h-full max-h-full flex-1',
              value === item.value ? 'font-medium' : 'bg-transparent',
              itemClassName,
            )}
          >
            {item.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </BaseTabs>
  )
}
